import { useState } from "react"
import { colors } from "../../../../utils/colors"
import { Collumn, Row } from "../../../Layouts"
import styles from './index.module.css'
import { useFormContext } from "react-hook-form"
import { FormDataFullForm } from "../../../../pages/FullForm"
import FileInput from "../../../FileInput"

const Screen1 = () => {
    const [businessFelony, setBusinessFelony] = useState<string>();

    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext<FormDataFullForm>();

    return (
        <Collumn style={{ gap: 22 }}>
            <span className={styles.subtitle}>Has the business (i.e., the same proprietorship, or corporation) or any officer, director or general partner of the business
                ever been convicted of any felony under state, federal or foreign law?
            </span>

            <Row
                style={{
                    gap: 20,
                    justifyContent: "center",
                }}
            >
                <button
                    type="button"
                    onClick={() => {
                        setBusinessFelony('yes')
                        setValue('certificationsBusinessFelony', 'yes')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: businessFelony === 'yes' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: businessFelony === 'yes' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        Yes
                    </span>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        setBusinessFelony('no')
                        setValue('certificationsBusinessFelony', 'no')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: businessFelony === 'no' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: businessFelony === 'no' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        No
                    </span>
                </button>
            </Row>

            {
                businessFelony === 'yes' && (
                    <Collumn>
                        <span className={styles.subtitle}>
                            Please attached a detailed explanation<span style={{ color: colors.primaryOrange }}>*</span>
                        </span>
                        <textarea
                            {...register("certificationsBusinessFelonyExplanation")}
                            className={styles.input}
                            placeholder="Digite..."
                            rows={5}
                            style={{ marginTop: 8 }}
                        />
                        <span className={styles.error}>{errors?.certificationsBusinessFelonyExplanation?.message ?? ""}</span>

                        <span className={styles.subtitle} style={{ marginTop: 22 }}>
                            Attach a file, if you have one
                        </span>
                        <FileInput registerName={'certificationsBusinessFelonyFile'} />
                        <span className={styles.error}>{errors?.certificationsBusinessFelonyFile?.message ?? ""}</span>

                    </Collumn>
                )
            }
        </Collumn>
    )
}

export default Screen1;
