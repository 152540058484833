"use client";
import { Collumn } from "../Layouts";
import styles from './index.module.css'
import { Fragment } from "react";
import FullFormContainer from "../FullFormContainer";

import FullFormComponents from "../FullFormComponents";


const FullFormStep2: React.FC<any> = ({ step, setStep }) => {
  const formConfigCache = localStorage.getItem("formConfig");
  const formConfig = formConfigCache ? JSON.parse(formConfigCache) : [];
  const formFormatted = formConfig?.[step]?.subSteps

  return (
    <FullFormContainer step={step} setStep={setStep}>
      <Collumn style={{ width: 743, flexWrap: 'wrap' }}>
        {formFormatted?.map((item: any, i: number) => {
          return (
            <Fragment key={i}>
              <span className={styles.stepTitle}>
                {item?.title}
              </span>
              <FullFormComponents step={item} />
            </Fragment>
          )
        })}
      </Collumn>
    </FullFormContainer >
  )
};

export default FullFormStep2;
