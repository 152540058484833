import { MdCheck } from "react-icons/md";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import { Fragment } from "react/jsx-runtime";
import { colors } from "../../utils/colors";

interface IProgressSteps {
  step: number;
  child?: any;
}

const stepsTitle = [
  "Commercial Information",
  "Questionnaire",
  "Credit",
  "Certifications",
  "Authorization Agreement",
  "Documents"
]

const ProgressSteps: React.FC<IProgressSteps> = ({ step, child }) => {
  return (
    <Row
      style={{
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {stepsTitle.map((item, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && (
              <CenteredDiv style={{ flex: 1 }}>
                <div
                  style={{
                    minWidth: 150,
                    width: "100%",
                    height: 4,
                    background: index <= step ? "#EA7100" : "#EEEEF5",
                  }}
                />
              </CenteredDiv>
            )}
            <Collumn key={index}>
              {index < step && (
                <CenteredDiv style={{ width: 21, height: 21, borderRadius: 10, backgroundColor: colors.primaryOrange }}>
                  <MdCheck size={14} color="white" />
                </CenteredDiv>
              )}
              {index === step && (
                <CenteredDiv style={{ width: 13, height: 13, borderRadius: 50, backgroundColor: ' white', border: '4px solid #ea7100' }}>
                  <MdCheck size={14} color="white" />
                </CenteredDiv>

              )}
              {index > step && (
                <div style={{ width: 21, height: 21, borderRadius: 50, backgroundColor: '#EEEEF5', }} />
              )}
              <span
                style={{
                  textAlign: "center",
                  fontFamily: "InterSemiBold",
                  fontSize: 14,
                  position: "absolute",
                  top: 30,
                  width: 90,
                  marginLeft: -35,
                  color: index <= step ? "#161616" : "#9C9DA7",
                }}
              >
                {item}
              </span>
              {child}
            </Collumn>
          </Fragment>
        );
      })}
    </Row>
  );
};

export default ProgressSteps;
