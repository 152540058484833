import { useState } from "react"
import { colors } from "../../../../utils/colors"
import { Collumn, Row } from "../../../Layouts"
import styles from './index.module.css'
import { useFormContext } from "react-hook-form"
import { FormDataFullForm } from "../../../../pages/FullForm"
import FileInput from "../../../FileInput"

const Screen4 = () => {
    const [revokedBusiness, setRevokedBusiness] = useState<string>();

    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext<FormDataFullForm>();

    return (
        <Collumn style={{ gap: 22 }}>
            <span className={styles.subtitle}>
                Has any of the following occurred or is pending with respect to the proposed agent, any director or officer of the proposed agent,
                or any significant subsidiary of the proposed agent?
            </span>

            <span className={styles.subtitle}>
                Any court or governmental agency has, for any reason, suspended or revoked any license or other authorization or
                qualification to engage in an profession, occupation, vocation or other business activity;
            </span>

            <Row
                style={{
                    gap: 20,
                    justifyContent: "center",
                }}
            >
                <button
                    type="button"
                    onClick={() => {
                        setRevokedBusiness('yes')
                        setValue('certificationsRevokedBusinessActivity', 'yes')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: revokedBusiness === 'yes' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: revokedBusiness === 'yes' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        Yes
                    </span>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        setRevokedBusiness('no')
                        setValue('certificationsRevokedBusinessActivity', 'no')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: revokedBusiness === 'no' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: revokedBusiness === 'no' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        No
                    </span>
                </button>
            </Row>

            {
                revokedBusiness === 'yes' && (
                    <Collumn>
                        <span className={styles.subtitle}>
                            Please attached a detailed explanation<span style={{ color: colors.primaryOrange }}>*</span>
                        </span>
                        <textarea
                            {...register("certificationsRevokedBusinessActivityExplanation")}
                            className={styles.input}
                            placeholder="Digite..."
                            rows={5}
                            style={{ marginTop: 8 }}
                        />
                        <span className={styles.error}>{errors?.certificationsRevokedBusinessActivityExplanation?.message ?? ""}</span>

                        <span className={styles.subtitle} style={{ marginTop: 22 }}>
                            Attach a file, if you have one
                        </span>
                        <FileInput registerName={'certificationsRevokedBusinessActivityFile'} />
                        <span className={styles.error}>{errors?.certificationsRevokedBusinessActivityFile?.message ?? ""}</span>

                    </Collumn>
                )
            }
        </Collumn>
    )
}

export default Screen4;
