"use client";
import { Collumn } from "../Layouts";
import { Fragment } from "react";
import styles from './index.module.css'
import FullFormContainer from "../FullFormContainer";
import { useNavigate } from "react-router-dom";
import FullFormComponents from "../FullFormComponents";

const FullFormStep1: React.FC<any> = ({ step, setStep }) => {
  const formConfigCache = localStorage.getItem("formConfig");
  const formConfig = formConfigCache ? JSON.parse(formConfigCache) : [];
  const formFormatted = formConfig?.[step]?.subSteps

  // const {
  //   control,
  // } = useFormContext<FormDataFullForm>();

  // const { } = useFieldArray({
  //   control,
  //   name: "competitors"
  // })
  const navigate = useNavigate()

  return (
    <FullFormContainer step={step} setStep={setStep} onGoBack={() => navigate('/fill-full-form')}>
      <Collumn style={{ width: 743, flexWrap: 'wrap' }}>
        {formFormatted?.map((step: any, i: number) => {
          return (
            <Fragment key={i}>
              <span className={styles.stepTitle}>
                {step?.title}
              </span>
              <FullFormComponents step={step} />
            </Fragment>
          )
        })}
      </Collumn>
    </FullFormContainer >
  )
};

export default FullFormStep1;
