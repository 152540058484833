"use client";
import Header from "../../components/Header";
import Steps from "../../components/Steps";
import { useState } from "react";
import { Collumn } from "../../components/Layouts";
import RegisterForm from "../../components/RegisterForm";

const Register = () => {
  const [step, setStep] = useState(1);

  return (
    <>
      <Header modeDark />
      <Collumn
        style={{
          minHeight: "100vh",
          background: "white",
          alignItems: "center",
        }}
      >
        <Steps step={step} secondStepTitle="Credenciais" />
        <RegisterForm step={step} setStep={setStep} />
      </Collumn>
    </>
  );
};

export default Register;
