import React from "react";
import { IoArrowForward } from "react-icons/io5";
import { colors } from "../../utils/colors";
import { Collumn } from "../Layouts";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/layout";
interface BoxLogoVerticalProps {
  Icon: any;
  title: string;
  subtitle: string;
  href: string;
}

const BoxLogoVertical: React.FC<BoxLogoVerticalProps> = ({
  Icon,
  title,
  href,
  subtitle,
}) => {
  const { t } = useTranslation()
  const { width: widthScreen } = useWindowDimensions()

  return (
    <Collumn
      style={{
        width: widthScreen > 550 ? 350 : '75vw',
        height: 360,
        borderRadius: 8,
        border: "1px solid #DEDFE4",
        padding: "30px 26px",
      }}
    >
      <div>{Icon}</div>
      <div style={{ width: '75%', marginTop: 20, marginBottom: 24 }}>
        <span
          style={{
            fontFamily: "CitrinaBold",
            fontSize: 24,
            color: colors.neutralGray04,
          }}
        >
          {title}
        </span>
      </div>

      <div style={{ width: '80%' }}>
        <span
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            color: colors.neutralGray05,
          }}
        >
          {subtitle}
        </span>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <a
          href={href}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            gap: 4,
          }}
        >
          <span
            style={{
              color: colors.primaryOrange,
              fontFamily: "InterSemiBold",
              fontSize: 14,
            }}
          >
            {t('Home.Section2.Knowmore')}
          </span>
          <IoArrowForward
            style={{ width: 24, height: 24 }}
            color={colors.primaryOrange}
          />
        </a>
      </div>
    </Collumn>
  )
};

export default BoxLogoVertical;
