// src/components/Alert.js
import React, { useState } from "react";
import styles from "./index.module.css";
import { GrClose } from "react-icons/gr";
import { CenteredDiv, Collumn } from "../Layouts";
import { ApproveButton, RejectButton } from "../Buttons";
import VerificationInput from "react-verification-input";

interface IAlert {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  approveText: string;
  rejectText?: string;
  onApprove: () => void;
}

const AlertConfirmationCode: React.FC<IAlert> = ({
  open,
  title,
  message,
  onClose,
  approveText,
  rejectText,
  onApprove,
}) => {
  const [hasError, setError] = useState(false);
  // const [code, setCode] = useState("");

  const onConfirmCode = async () => {
    try {
      if (onApprove) onApprove();
    } catch (error: any) {
      console.log(error);
    }
  };

  const onResend = async () => {
    try {
      setError(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  return open ? (
    <div className={styles["alert-background"]}>
      <div className={styles["alert-container"]}>
        <GrClose
          onClick={onClose}
          color="#3E322E"
          size={20}
          className={styles["alert-close-button"]}
        />
        <CenteredDiv>
          <span className={styles.title}>{title}</span>
        </CenteredDiv>
        <div className={styles["alert-message"]}>{message}</div>
        <CenteredDiv
          style={{
            margin: "20px 0px",
            flexDirection: "column",
          }}
        >
          <Collumn style={{ gap: 8 }}>
            <VerificationInput
              validChars="0-9"
              length={5}
              placeholder=""
              autoFocus
              classNames={{
                character: hasError ? styles.characterError : styles.character,
                characterInactive: styles.characterInactive,
                characterSelected: styles.characterSelected,
                characterFilled: styles.characterFilled,
              }}
            />
            {hasError && <span className={styles.error}>Código incorreto</span>}
          </Collumn>
        </CenteredDiv>
        {approveText && (
          <ApproveButton
            title={approveText}
            onClick={() => onConfirmCode()}
            style={{ fontSize: 14 }}
          />
        )}
        {rejectText && (
          <RejectButton
            title={rejectText}
            onClick={onResend}
            style={{ fontSize: 14 }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default AlertConfirmationCode;
