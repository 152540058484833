"use client";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import Alert from "../Alert";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegisterFormStep1 from "../RegisterFormStep1";
import RegisterFormStep2 from "../RegisterFormStep2";
import AlertConfirmationCode from "../AlertConfirmationCode";

interface IRegisterForm {
  step: number;
  setStep: any;
}

export interface IRegisterFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  authorization: boolean;
}

const RegisterForm: React.FC<IRegisterForm> = ({ step, setStep }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [leaveAlertIsOpen, setLeaveAlertIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmationAlert, setConfirmationAlert] = useState(false);

  const schemaStep1: any = yup.object().shape({
    name: yup.string().required("invalid field"),
    email: yup.string().email("formato inválido").required("invalid field"),
    authorization: yup.boolean().oneOf([true], "Precisa aceitar os termos"),
  });

  const schemaStep2: any = yup.object().shape({
    password: yup
      .string()
      .required("Digite uma senha válida")
      .min(6, "senha deve ter 6 caracteres")
      .matches(/[A-Z]/, "letra maiúscula faltante")
      .matches(/[a-z]/, "letra minúscula faltante")
      .matches(/[0-9]/, "número faltante"),
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, "caracter especial faltante"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Senhas não coincidem. Tente novamente")
      .required("Digite uma senha válida"),
  });

  const methods = useForm<IRegisterFormData>({
    resolver: yupResolver(step === 1 ? schemaStep1 : schemaStep2),
    mode: "onChange",
    defaultValues: {
      authorization: false,
      name: params.get("name") ?? "",
      email: params.get("email") ?? "",
    },
  });

  const { handleSubmit, watch } = methods;
  const isDisabled = watch("authorization") === false;

  const onSubmit: SubmitHandler<IRegisterFormData> = (data: any) => {
    if (step === 1) {
      setStep((prev: number) => prev + 1);
      console.log(JSON.stringify(data));
    } else {
      console.log(JSON.stringify(data));
      setConfirmationAlert(true);
    }
  };

  return (
    <>
      <span className={styles.stepTitle}>Cadastro</span>
      <FormProvider {...methods}>
        <form
          style={{
            width: 743,
            paddingBottom: 60,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {step === 1 && <RegisterFormStep1 />}
          {step === 2 && <RegisterFormStep2 />}
          <Row
            style={{
              gap: 20,
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (step >= 2) setStep((prev: number) => prev - 1);
                else {
                  setLeaveAlertIsOpen(true);
                }
              }}
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: "#EBEBEB",
                border: "none",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#161616",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                VOLTAR
              </span>
            </button>

            <button
              type="submit"
              disabled={isDisabled}
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: isDisabled ? "#DEDFE4" : colors.primaryOrange,
                border: "none",
                cursor: isDisabled ? "" : "pointer",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                {step === 1 ? "PRÓXIMO" : "CRIAR CONTA"}
              </span>
            </button>
          </Row>
        </form>
      </FormProvider>
      <Alert
        message="Você já pode entrar na sua conta."
        title="Cadastro concluído com sucesso!"
        onClose={() => {
          setOpen(false);
          navigate("/login");
        }}
        approveText="LOGIN"
        open={open}
      />
      <Alert
        title="Sair do Formulário"
        message="Você tem certeza que deseja sair do formulário agora? Todas as suas informações serão perdidas."
        onApprove={() => navigate("/become-an-agent")}
        open={leaveAlertIsOpen}
        onClose={() => setLeaveAlertIsOpen(false)}
        approveText="Sair"
        rejectText="Voltar ao Formulário"
      />
      <AlertConfirmationCode
        title="Confirmação"
        message="Um código de confirmação foi enviado para o seu e-mail"
        onClose={() => setConfirmationAlert(false)}
        rejectText="REENVIAR CÓDIGO"
        approveText="CONFIRMAR"
        onApprove={() => {
          setConfirmationAlert(false);
          setOpen(true);
        }}
        open={confirmationAlert}
      />
    </>
  );
};

export default RegisterForm;
