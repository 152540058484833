export const colors = {
  primaryOrange: "#EA7100",
  primaryLight: "#FFC790",
  primaryMedium: "#A13500",
  primaryDark: "#7B3300",
  primarySuperLight: "#FEF8EB",
  neutralGray01: "#EBEBEB",
  neutralGray02: "#3E322E",
  neutralGray03: "#FFFFFF",
  neutralGray04: "#161616",
  neutralGray05: "#6A6C72",
  secondaryDark: "#2ECFFF",
  secondaryDefault: "#50EABB",
  secondaryLight: "#FFB2EA",
  secondarySuperLight: "#FAFF53",
};
