"use client";
import { useFormContext } from "react-hook-form";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import { IRegisterFormData } from "../RegisterForm";
import { Checkbox } from "@mui/material";

const RegisterFormStep1: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IRegisterFormData>();

  return (
    <Collumn style={{ flex: 1, gap: 22, marginBottom: 100 }}>
      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          Nome Completo
          <span style={{ color: colors.primaryOrange }}>*</span>
        </label>

        <input
          {...register("name")}
          className={styles.input}
          placeholder="Digite o nome..."
        />
        <span className={styles.error}>{errors?.name?.message ?? ""}</span>
      </Collumn>

      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          E-mail
          <span style={{ color: colors.primaryOrange }}>*</span>
        </label>

        <input
          {...register("email")}
          type="email"
          className={styles.input}
          placeholder="Digite o e-mail..."
        />
        <span className={styles.error}>{errors?.email?.message ?? ""}</span>
      </Collumn>

      <Row style={{ alignItems: "flex-start" }}>
        <CenteredDiv>
          <Checkbox
            {...register("authorization")}
            sx={{
              color: "#f0f0f0",
              borderRadius: 5,
              "&.Mui-checked": {
                color: colors.primaryOrange,
              },
            }}
          />
        </CenteredDiv>

        <span
          style={{
            color: colors.primaryOrange,
            fontFamily: "InterSemiBold",
            marginTop: 2,
          }}
        >
          *
        </span>
        <span className={styles.authorizationText}>
          Autorizo o Inter a tratar meus dados pessoais para envio de
          comunicações sobre seus produtos e serviços e também estou de acordo
          com a{" "}
          <span
            style={{ color: colors.primaryOrange, fontFamily: "InterSemiBold" }}
          >
            política de privacidade.
          </span>
        </span>
      </Row>
    </Collumn>
  );
};

export default RegisterFormStep1;
