import { BsDownload } from "react-icons/bs"
import { Collumn, Row } from "../Layouts"
import styles from './index.module.css'
import { useFormContext } from "react-hook-form";
import { FormDataFullForm } from "../../pages/FullForm";
import pdf from '../../assets/pdf.svg'
import { ChangeEvent, useState } from "react";
import moment from "moment";


interface FileInputProps {
    registerName: any,
    title?: string,
    isMultiple?:boolean
}

const FileInput: React.FC<FileInputProps> = ({ title, registerName, isMultiple}) => {
    const {
        register,
        setValue
    } = useFormContext<FormDataFullForm>();

    const [fileInfo, setFileInfo] = useState("")

    const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const sizeInKB = (file.size) * 0.0009765625;
            const dateFormatted = moment(file.lastModified).format("DD MMM, YYYY")
            if (sizeInKB > 1000) {
                const sizeInMB = sizeInKB / 1024;
                setFileInfo(`${sizeInMB.toFixed(2)} MB . ${dateFormatted}`)
            } else {
                setFileInfo(`${sizeInKB.toFixed(2)} KB . ${dateFormatted}`)
            }
            setValue(registerName, new FormData().append(registerName,file))
        }
    }

    return (
        <Collumn>
            {title && <span className={styles.blackText}>{title}</span>}
            <label htmlFor="file-upload">
                <Row className={styles.downloadContainer}>
                    <Row style={{ gap: 12 }}>
                        <img src={pdf} alt="pdf-icon" />
                        <Collumn>
                            <span className={styles.uploadText}>Upload</span>
                            <span className={styles.downloadInfo}>{fileInfo}</span>
                        </Collumn>
                    </Row>
                    <BsDownload size={16} color="#71839B" />
                </Row>
            </label>
            <input multiple={!!isMultiple} id="file-upload" {...register(registerName)} type="file" style={{ display: "none" }} onChange={handleFile} />
        </Collumn>
    )
}

export default FileInput