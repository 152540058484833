import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import logo from "../../assets/logo.svg";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { colors } from "../../utils/colors";
import { IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/layout";
import carousel01 from "../../assets/carousel01.jpg";

interface FormData {
  username: string;
  password: string;
}


const Login = () => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate();

  const schema: any = yup.object().shape({
    username: yup
      .string()
      .email("Formato Inválido")
      .required("invalid field"),
    password: yup.string().required("invalid field"),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [seePassword, setSeePassword] = useState(false);

  const { handleSubmit, register } = methods;

  const onSubmit: SubmitHandler<FormData> = (data: any) => {
    console.log(JSON.stringify(data));
    navigate('/fill-full-form')
  };

  const Form = (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Collumn style={{ width: "100%", position: "relative" }}>
        <label className={styles.label}>Username</label>
        <input className={styles.input} {...register("username")} />
        <Row style={{ justifyContent: "space-between", marginTop: 32 }}>
          <label className={styles.label}>Senha</label>
          <span
            className={styles.forgotPasswordText}
            onClick={() => navigate("/forgot-password")}
          >
            Esqueceu sua senha?
          </span>
        </Row>
        <input
          className={styles.input}
          {...register("password")}
          type={seePassword ? "" : "password"}
        />
        {seePassword ? (
          <MdVisibility
            color={colors.primaryOrange}
            size={24}
            onClick={() => setSeePassword((prev) => !prev)}
            style={{
              position: "absolute",
              top: 157,
              right: 10,
              cursor: "pointer",
            }}
          />
        ) : (
          <MdVisibilityOff
            color={colors.primaryOrange}
            size={24}
            onClick={() => setSeePassword((prev) => !prev)}
            style={{
              position: "absolute",
              top: 157,
              right: 10,
              cursor: "pointer",
            }}
          />
        )}
      </Collumn>
      <CenteredDiv style={{ marginTop: 32, marginBottom: 12 }}>
        <button onClick={() => navigate('/fill-full-form')} className={styles.continueButton}>
          <CenteredDiv>
            Continuar <IoArrowForward size={20} style={{ marginLeft: 8 }} />
          </CenteredDiv>
        </button>
      </CenteredDiv>

      <Link href="/" >
        <CenteredDiv>
          <span className={styles.goBackText}>
            Voltar para o início
          </span>
        </CenteredDiv>
      </Link>
    </form>
  )

  return width >= 900 ? (
    <Row style={{ flex: 1 }}>
      <div
        style={{
          width: "50%",
          height: "100vh",
          background: `url(${carousel01}) lightgray 0px 0px / 100% 100% no-repeat`
        }}
      />
      <CenteredDiv
        style={{
          width: "50%",
          gap: 22,
          flexDirection: "column",
          padding: "0px 52px",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} alt="logo" width={300} />
        <span className={styles.title}>
          Faça o login na sua conta <br />
          Inter&Co!
        </span>
        {Form}
      </CenteredDiv>
    </Row>
  ) : (
    <CenteredDiv
      style={{
        minHeight: '100vh',
        background: `url(${carousel01}) 50% / cover no-repeat`,
        padding: 20
      }}>
      <CenteredDiv
        style={{
          width: '100%',
          gap: 22,
          flexDirection: "column",
          padding: 32,
          borderRadius: 8,
          background: 'white',
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)'
        }}
      >
        <img src={logo} alt="logo" width={width < 500 ? 250 : 300} />
        <span className={styles.title} style={{
          fontSize: width <= 550 ? 28 : 48
        }}>
          Faça o login na sua conta <br />
          Inter&Co!
        </span>
        {Form}
      </CenteredDiv>
    </CenteredDiv >
  );
};

export default Login;
