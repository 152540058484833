import { useState } from "react"
import { colors } from "../../../../utils/colors"
import { Collumn, Row } from "../../../Layouts"
import styles from './index.module.css'
import { useFormContext } from "react-hook-form"
import { FormDataFullForm } from "../../../../pages/FullForm"
import FileInput from "../../../FileInput"

const Screen7 = () => {
    const [judgmentSuffered, setJudgmentSuffered] = useState<string>();

    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext<FormDataFullForm>();

    return (
        <Collumn style={{ gap: 22 }}>
            <span className={styles.subtitle}>
            Suffered judgment in any civil action based upon conduct involving fraud or dishonesty or;
            </span>

            <Row
                style={{
                    gap: 20,
                    justifyContent: "center",
                }}
            >
                <button
                    type="button"
                    onClick={() => {
                        setJudgmentSuffered('yes')
                        setValue('certificationsJudgmentSuffered', 'yes')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: judgmentSuffered === 'yes' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: judgmentSuffered === 'yes' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        Yes
                    </span>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        setJudgmentSuffered('no')
                        setValue('certificationsJudgmentSuffered', 'no')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: judgmentSuffered === 'no' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: judgmentSuffered === 'no' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        No
                    </span>
                </button>
            </Row>

            {
                judgmentSuffered === 'yes' && (
                    <Collumn>
                        <span className={styles.subtitle}>
                            Please attached a detailed explanation<span style={{ color: colors.primaryOrange }}>*</span>
                        </span>
                        <textarea
                            {...register("certificationsJudgmentSufferedExplanation")}
                            className={styles.input}
                            placeholder="Digite..."
                            rows={5}
                            style={{ marginTop: 8 }}
                        />
                        <span className={styles.error}>{errors?.certificationsJudgmentSufferedExplanation?.message ?? ""}</span>

                        <span className={styles.subtitle} style={{ marginTop: 22 }}>
                            Attach a file, if you have one
                        </span>
                        <FileInput registerName={'certificationsJudgmentSufferedFile'} />
                        <span className={styles.error}>{errors?.certificationsJudgmentSufferedFile?.message ?? ""}</span>

                    </Collumn>
                )
            }
        </Collumn>
    )
}

export default Screen7;
