import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import logo from "../../assets/logo.svg";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IoArrowForward,
  IoCheckmarkCircleOutline,
  IoCloseOutline,
} from "react-icons/io5";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/layout";
import carousel01 from "../../assets/carousel01.jpg";

interface FormData {
  email: string;
}

const ForgotPassword = () => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate();
  const [greenBoxOpen, setGreenBoxOpen] = useState(false);

  const schema: any = yup.object().shape({
    email: yup.string().email("formato inválido").required("invalid field"),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const { handleSubmit, register } = methods;

  const onSubmit: SubmitHandler<FormData> = (data: any) => {
    console.log(JSON.stringify(data));
    setGreenBoxOpen(true);
    navigate("/recover-password");
  };

  const Form = (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {greenBoxOpen && (
        <Row className={styles.greenBox}>
          <IoCheckmarkCircleOutline size={24} color="#008145" />
          <span>
            Um link para recuperar sua senha foi enviado para o seu e-mail.
            Confira!{" "}
          </span>
          <IoCloseOutline
            size={24}
            color="#3E322E"
            onClick={() => setGreenBoxOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
          />
        </Row>
      )}
      <Collumn>
        <label className={styles.label}>Insira seu e-mail</label>
        <input className={styles.input} {...register("email")} />
      </Collumn>

      <CenteredDiv style={{ marginTop: 32, flexDirection: "column" }}>
        <button type="submit" className={styles.continueButton}>
          <CenteredDiv>
            Recuperar sua senha{" "}
            <IoArrowForward size={20} style={{ marginLeft: 8 }} />
          </CenteredDiv>
        </button>
        <CenteredDiv style={{ marginTop: 12 }}>
          <Link href="/login">
            <span className={styles.forgotPasswordText}>
              Voltar para o login
            </span>
          </Link>
        </CenteredDiv>
      </CenteredDiv>
    </form>
  )

  return width >= 900 ? (
    <Row style={{ flex: 1 }}>
      <div
        style={{
          width: "50%",
          height: "100vh",
          background: `url(${carousel01}) lightgray -1430.798px 0px / 306.592% 100% no-repeat`
        }}
      />
      <CenteredDiv
        style={{
          width: "50%",
          gap: 32,
          flexDirection: "column",
          padding: "0px 52px",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} alt="logo" width={300} />
        <span className={styles.title}>Esqueceu a sua senha?</span>
        {Form}
      </CenteredDiv>
    </Row>
  ) : (
    <CenteredDiv
      style={{
        minHeight: '100vh',
        background: `url(${carousel01}) 50% / cover no-repeat`,
        padding: 20
      }}>
      <CenteredDiv
        style={{
          width: "100%",
          gap: 32,
          flexDirection: "column",
          padding: 32,
          background: ' white',
          borderRadius: 8,
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)'
        }}
      >
        <img src={logo} alt="logo" width={width < 500 ? 250 : 300} />
        <span className={styles.title} style={{
          fontSize: width <= 550 ? 28 : 48
        }}>Esqueceu a sua senha?</span>
        {Form}
      </CenteredDiv>
    </CenteredDiv>
  );
};

export default ForgotPassword;
