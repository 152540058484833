"use client";
import { useFormContext } from "react-hook-form";
import { Collumn } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import { IRegisterFormData } from "../RegisterForm";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useState } from "react";

const RegisterFormStep2: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IRegisterFormData>();

  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);

  const [has6Length, setHas6Length] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  const handlePassword = (password: string) => {
    if (password === " ") return;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const has6Length = password.trim().length >= 6;
    const hasNumber = /[0-9]/.test(password);

    if (hasUpperCase) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (hasLowerCase) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (hasNumber) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }

    if (has6Length) {
      setHas6Length(true);
    } else {
      setHas6Length(false);
    }
  };

  return (
    <Collumn style={{ flex: 1, gap: 22 }}>
      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          Senha
        </label>

        <Collumn style={{ position: "relative" }}>
          <input
            {...register("password")}
            className={styles.input}
            type={seePassword ? "" : "password"}
            onChange={(e) => handlePassword(e.target.value)}
          />
          {seePassword ? (
            <MdVisibility
              color={colors.primaryOrange}
              size={24}
              onClick={() => setSeePassword((prev) => !prev)}
              style={{
                position: "absolute",
                top: 15,
                right: 10,
                cursor: "pointer",
              }}
            />
          ) : (
            <MdVisibilityOff
              color={colors.primaryOrange}
              size={24}
              onClick={() => setSeePassword((prev) => !prev)}
              style={{
                position: "absolute",
                top: 15,
                right: 10,
                cursor: "pointer",
              }}
            />
          )}
        </Collumn>

        <span className={styles.error}>{errors?.password?.message ?? ""}</span>
      </Collumn>

      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          Repita a senha
        </label>

        <Collumn style={{ position: "relative" }}>
          <input
            {...register("confirmPassword")}
            type={seeConfirmPassword ? "" : "password"}
            className={styles.input}
          />
          {seeConfirmPassword ? (
            <MdVisibility
              onClick={() => setSeeConfirmPassword(false)}
              color={colors.primaryOrange}
              size={26}
              style={{
                position: "absolute",
                top: 15,
                right: 10,
                cursor: "pointer",
              }}
            />
          ) : (
            <MdVisibilityOff
              size={26}
              onClick={() => setSeeConfirmPassword(true)}
              color={colors.primaryOrange}
              style={{
                position: "absolute",
                top: 15,
                right: 10,
                cursor: "pointer",
              }}
            />
          )}
        </Collumn>
        <span className={styles.error}>
          {errors?.confirmPassword?.message ?? ""}
        </span>
      </Collumn>

      <Collumn style={{ marginBottom: 100 }}>
        <span className={styles.passwordRules}>
          Sua senha deve conter:{" "}
          <li
            style={{
              color: !hasLowerCase || !hasUpperCase ? "#EA7100" : "#161616",
            }}
          >
            Ao menos uma letra maíuscula e minúscula;
          </li>
          <li
            style={{
              color: !hasNumber ? "#EA7100" : "#161616",
            }}
          >
            Ao menos um número;
          </li>
          <li
            style={{
              color: !has6Length ? "#EA7100" : "#161616",
            }}
          >
            Mínimo de 6 caracteres.
          </li>
        </span>
      </Collumn>
    </Collumn>
  );
};

export default RegisterFormStep2;
