"use client";
import { useFormContext } from "react-hook-form";
import { Collumn, Row } from "../Layouts";
import styles from "./index.module.css";
import "./index.css";
import { colors } from "../../utils/colors";
import { FormData } from "../ShortForm";
import { useState } from "react";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const ShortFormStep1: React.FC = () => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<FormData>();

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  return (
    <Collumn style={{ flex: 1, gap: 22 }}>
      <Row>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Nome Completo
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("name")}
            className={styles.input}
            placeholder="Digite o nome..."
          />
          <span className={styles.error}>{errors?.name?.message ?? ""}</span>
        </Collumn>
      </Row>

      <Row style={{ gap: 22 }}>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            E-mail
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("email")}
            type="email"
            className={styles.input}
            placeholder="Digite o e-mail..."
          />
          <span className={styles.error}>{errors?.email?.message ?? ""}</span>
        </Collumn>

        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Telefone Celular
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("phone")}
            className={styles.input}
            placeholder="Digite o telefone..."
          />
          <span className={styles.error}>{errors?.phone?.message ?? ""}</span>
        </Collumn>
      </Row>

      <Row style={{ gap: 22 }}>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            ZIP Code
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("zip")}
            className={styles.input}
            placeholder="Digite o ZIP Code..."
          />
          <span className={styles.error}>{errors?.zip?.message ?? ""}</span>
        </Collumn>

        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Endereço Comercial
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("address")}
            className={styles.input}
            placeholder="Digite o endereço..."
          />
          <span className={styles.error}>{errors?.address?.message ?? ""}</span>
        </Collumn>
      </Row>

      <Row style={{ gap: 22 }}>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Nome da Empresa/Loja
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("companyName")}
            className={styles.input}
            placeholder="Digite o nome da empresa/loja..."
          />
          <span className={styles.error}>{errors?.companyName?.message ?? ""}</span>
        </Collumn>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            País
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <CountrySelect
            {...register("country")}
            onChange={(e: any) => {
              setCountryid(e.id);
              setValue("country", e.name);
            }}
            containerClassName={styles.stateContainer}
            inputClassName={styles.stateInput}
            showFlag={false}
            placeHolder="Digite o país..."
          />

          <span className={styles.error}>{errors?.country?.message ?? ""}</span>
        </Collumn>
      </Row>

      {watch("country") && (
        <Row style={{ gap: 22 }}>
          <Collumn
            style={{
              width: "100%",
            }}
          >
            <label
              style={{
                color: "#161616",
                fontFamily: "InterSemiBold",
                fontSize: 14,
                marginBottom: 9,
              }}
            >
              Estado
              <span style={{ color: colors.primaryOrange }}>*</span>
            </label>

            <StateSelect
              {...register("state")}
              onChange={(e: any) => {
                setstateid(e.id);
                setValue("state", e.name);
              }}
              countryid={countryid}
              containerClassName={styles.stateContainer}
              inputClassName={styles.stateInput}
              placeHolder="Digite o estado..."
            />
            <span className={styles.error}>
              {errors?.state?.message ?? ""}
            </span>
          </Collumn>

          <Collumn
            style={{
              width: "100%",
            }}
          >
            {watch("state") ? (
              <>
                <label
                  style={{
                    color: "#161616",
                    fontFamily: "InterSemiBold",
                    fontSize: 14,
                    marginBottom: 9,
                  }}
                >
                  Cidade
                  <span style={{ color: colors.primaryOrange }}>*</span>
                </label>

                <CitySelect
                  {...register("city")}
                  countryid={countryid}
                  stateid={stateid}
                  onChange={(e: any) => {
                    setValue("city", e.name);
                  }}
                  containerClassName={styles.stateContainer}
                  inputClassName={styles.stateInput}
                  placeHolder="Digite a cidade"
                />

                <span className={styles.error}>
                  {errors?.city?.message ?? ""}
                </span>
              </>
            ) : (
              <div />
            )}
          </Collumn>
        </Row>
      )}

      <Row style={{ gap: 22 }}>
        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Endereço da Loja
            <span style={{ color: colors.primaryOrange }}>*</span>
          </label>

          <input
            {...register("storeAddress")}
            className={styles.input}
            placeholder="Digite o endereço da loja..."
          />
          <span className={styles.error}>{errors?.storeAddress?.message ?? ""}</span>
        </Collumn>

        <Collumn
          style={{
            width: "100%",
          }}
        >
          <label
            style={{
              color: "#161616",
              fontFamily: "InterSemiBold",
              fontSize: 14,
              marginBottom: 9,
            }}
          >
            Mensagem
          </label>

          <input
            {...register("message")}
            className={styles.input}
            placeholder="Digite a mensagem..."
          />
          <span className={styles.error}>{errors?.message?.message ?? ""}</span>
        </Collumn>
      </Row>
    </Collumn>
  );
};

export default ShortFormStep1;
