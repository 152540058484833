"use client";
import { CenteredDiv } from "../../components/Layouts";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.css";
import findAgent from "../../assets/findAgent.png";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FindAgent = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Header modeDark />

      <div className={styles.container}>
        <CenteredDiv
          style={{
            gap: 22,
            flexDirection: "column",
          }}
        >
          <span className={styles.title}>{t('FindAgents.Title')}</span>
          <span className={styles.subTitle}>
            {t('FindAgents.BlackText')}{" "}
            <span style={{ color: colors.primaryOrange }}>{t('FindAgents.OrangeText')}</span>
          </span>
        </CenteredDiv>
        <CenteredDiv>
          <img src={findAgent} alt="agents-map" className={styles.map} />
        </CenteredDiv>
      </div>

      <Footer />
    </div>
  );
};

export default FindAgent;
