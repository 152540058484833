import React from "react";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import { colors } from "../../utils/colors";
import { MdCheck } from "react-icons/md";

interface ISteps {
  step: number;
  secondStepTitle?: string;
}

const Steps: React.FC<ISteps> = ({ step, secondStepTitle }) => {
  return (
    <Collumn
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 66,
      }}
    >
      <Row>
        {step === 1 ? (
          <div
            style={{
              width: 13,
              height: 13,
              backgroundColor: "#EEEEF5",
              borderRadius: 30,
              border:
                step === 1
                  ? `4px solid ${colors.primaryOrange}`
                  : "4px solid #EEEEF5",
            }}
          />
        ) : (
          <CenteredDiv
            style={{
              width: 21,
              height: 21,
              borderRadius: 30,
              backgroundColor: colors.primaryOrange,
            }}
          >
            <MdCheck color={"white"} />
          </CenteredDiv>
        )}
        
        <div style={{ height: 21 }}>
          <div
            style={{
              width: 403,
              backgroundColor: step >= 2 ? colors.primaryOrange : "#EEEEF5",
              height: 4,
              marginTop: 8.5,
            }}
          />
        </div>

        {step === 1 && (
          <div
            style={{
              width: 21,
              height: 21,
              backgroundColor: "#EEEEF5",
              borderRadius: 30,
            }}
          />
        )}

        {step === 2 && (
          <div
            style={{
              width: 13,
              height: 13,
              backgroundColor: "#EEEEF5",
              borderRadius: 30,
              border: `4px solid ${colors.primaryOrange}`,
            }}
          />
        )}

        {step === 3 && (
          <CenteredDiv
            style={{
              width: 21,
              height: 21,
              borderRadius: 30,
              backgroundColor: colors.primaryOrange,
            }}
          >
            <MdCheck color={"white"} />
          </CenteredDiv>
        )}
      </Row>

      <Row
        style={{
          justifyContent: "space-between",
          width: secondStepTitle ? 530 : 500,
          paddingRight: secondStepTitle ? 40 : 0,
          paddingLeft: secondStepTitle ? 0 : 20,
          marginTop: 8,
        }}
      >
        <span
          style={{
            color: "#1c1c1c",
            fontFamily: "InterSemiBold",
            fontSize: 14,
          }}
        >
          Contato
        </span>
        <span
          style={{
            color: step >= 2 ? "#1c1c1c" : "#9C9DA7",
            fontFamily: "InterSemiBold",
            fontSize: 14,
          }}
        >
          {secondStepTitle ?? "Revisão de dados"}
        </span>
      </Row>
    </Collumn>
  );
};

export default Steps;
