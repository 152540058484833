"use client";
import { Collumn } from "../Layouts";
import styles from "./index.module.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment } from "react";
import FullFormContainer from "../FullFormContainer";
import FullFormComponents from "../FullFormComponents";
import { useNavigate } from "react-router-dom";

const FullFormStep6: React.FC<any> = ({ step, setStep, skip, setSkip }) => {
  const formConfigCache = localStorage.getItem("formConfig");
  const formConfig = formConfigCache ? JSON.parse(formConfigCache) : [];
  const formFormatted = formConfig?.[step]?.subSteps

  const navigate = useNavigate()

  return (
    <FullFormContainer step={step} setStep={setStep} onNext={skip ? () => {
      navigate('/full-form-completed')
    } : undefined
    }>
      <Collumn style={{ width: 743, flexWrap: 'wrap' }}>
        {formFormatted?.map((item: any, i: number) => {
          return (
            <Fragment key={i}>
              <span className={styles.stepTitle}>
                {item?.title}
              </span>
              <FullFormComponents step={item} />
            </Fragment>
          )
        })}
      </Collumn>
    </FullFormContainer >
  )
};

export default FullFormStep6;
