import { CenteredDiv, Collumn, Row } from "../Layouts";
import { colors } from "../../utils/colors";
import styles from './index.module.css'
import FileInput from "../FileInput";
import DateInput from "../DateInput";
import { ErrorMessage } from "@hookform/error-message"
import { MdCheck } from "react-icons/md";
import { useFormContext } from "react-hook-form";
import { FormDataFullForm } from "../../pages/FullForm";
import React from "react";

const FullFormComponents: React.FC<{ step: any }> = ({ step }) => {
    const {
        setValue,
        register,
        formState: { errors },
        watch
    } = useFormContext<FormDataFullForm>();

    return (
        <Row style={{ gap: 22, width: 743, flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {step.fields.map((field: any, index: number) => {
                const widthFormatted = Number(field.size.replace("%", ""));
                const width = widthFormatted === 100 ? widthFormatted : widthFormatted - 2;
                if (field.elementType === 'INPUT_TEXT')
                    return (
                        <Collumn
                            key={index}
                            style={{
                                width: `${width}%`,
                            }}
                        >
                            <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9,
                                }}
                            >
                                {field.title ?? ''}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}

                            <input
                                {...register(field.name)}
                                className={styles.input}
                                placeholder="Type here..."
                            />

                            <ErrorMessage
                                errors={errors}
                                name={field.name}
                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                            />
                        </Collumn>
                    )

                if (field.elementType === 'INPUT_NUMBER')
                    return (
                        <Collumn
                            key={index}
                            style={{
                                width: `${width}%`,
                            }}
                        >
                            <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9,
                                }}
                            >
                                {field.title ?? ''}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}

                            <input
                                {...register(field.name)}
                                type="number"
                                className={styles.input}
                                placeholder="Type here..."
                            />

                            <ErrorMessage
                                errors={errors}
                                name={field.name}
                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                            />
                        </Collumn>
                    )

                if (field.elementType === 'RADIO_BUTTON_GROUP') {
                    const buttonSelected = watch(field.name);

                    return (
                        <Collumn
                            key={index}
                            style={{ width:'100%' }}
                        >
                            {field.title && <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9,
                                    width:'100%',
                                }}
                            >
                                {field.title ?? ''}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>}
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}

                            <Row
                                key={index}
                                style={{
                                    minWidth: 0,
                                    gap: 22,
                                }}
                            >
                                {
                                    field.options.map((item: any) => {

                                        if (field.layoutType === 'BUTTON')
                                            return (
                                                <CenteredDiv
                                                    key={item.order}
                                                    className={styles.button}
                                                    onClick={() => {
                                                        setValue(field.name, item.order)
                                                    }}
                                                    style={{
                                                        backgroundColor: buttonSelected === item.order ? colors.primaryOrange : "white",
                                                        color: buttonSelected === item.order ? "white" : colors.primaryOrange,
                                                        // width: `${100 / field.options.length}%`,
                                                        maxWidth: 200
                                                    }}>
                                                    {item.name}
                                                </CenteredDiv>
                                            )

                                        if (field.layoutType === 'DEFAULT')
                                            return (
                                                <label className={styles.radio}>
                                                    <input
                                                        type="radio"
                                                        {...register(field.name)}
                                                        value={item.value}
                                                    />
                                                    <CenteredDiv className={styles.checkmark}>
                                                        <MdCheck />
                                                    </CenteredDiv>
                                                    <CenteredDiv style={{ height: 28 }}>
                                                        {item.name}
                                                    </CenteredDiv>
                                                </label>
                                            )

                                        return null;
                                    }
                                    )
                                }
                            </Row>
                            <ErrorMessage
                                errors={errors}
                                name={field.name}
                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                            />
                        </Collumn>
                    )
                }

                if (field.elementType === 'INPUT_FILE')
                    return (
                        <Collumn
                            key={index}
                            style={{
                                width: `${width}%`,
                            }}
                        >
                            <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                }}
                            >
                                {field.title}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}

                            <FileInput registerName={field.name} />
                            <ErrorMessage
                                errors={errors}
                                name={field.name}
                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                            />
                        </Collumn>
                    )

                if (field.elementType === 'INPUT_DATE')
                    return (
                        <Collumn
                            key={index}
                            style={{
                                width: `${width}%`,
                            }}
                        >
                            <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9,
                                }}
                            >
                                {field.title ?? ''}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}

                            <DateInput registerName={field.name} />
                            <ErrorMessage
                                errors={errors}
                                name={field.name}
                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                            />
                        </Collumn>
                    )

                if (field.elementType === 'INPUT_SELECT')
                    return (
                        <Collumn
                            key={index}
                            style={{
                                width: `${width}%`,
                            }}
                        >
                            <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9
                                }}
                            >
                                {field.title}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}
                            <select {...register(field.name)} style={{ boxSizing: 'content-box' }} className={styles.input}
                                defaultValue={undefined}>
                                <option value={undefined}>-</option>
                                {field.options?.map((item: any) => (
                                    <option value={item.value}>{item.name}</option>
                                ))}
                            </select>
                        </Collumn>
                    )

                if (field.elementType === 'STATIC_TEXT')
                    return (
                        <>
                            {
                                field.paragraphs.map((item: any) => {
                                    return <span className={styles.subtitle}>{item.value}</span>
                                })
                            }
                        </>
                    )

                if (field.elementType === 'TEXTAREA')
                    return (
                        <Collumn key={index} style={{ width:'100%'}}>
                            {field.title && <label
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 14,
                                    marginBottom: 9
                                }}
                            >
                                {field.title ?? ''}
                                {field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                            </label>}
                            {field.subTitle && <span
                                style={{
                                    color: "#161616",
                                    fontFamily: "InterSemiBold",
                                    fontSize: 12,
                                    marginBottom: 9,
                                }}
                            >
                                {field.subTitle}
                            </span>}
                            <textarea
                                {...register(field.name)}
                                className={styles.input}
                                placeholder="Type here..."
                                rows={5}
                                style={{ minHeight: 100}}
                            />
                        </Collumn>
                    )

                if (field.elementType === 'FIELDS_GROUP_LIST')
                    return (
                        <Collumn key={index} style={{gap: 22}}>
                            {field?.subFields?.map((subField: any) => {
                                const widthFormatted = Number(subField.size.replace("%", ""));
                                const widthh = widthFormatted === 100 ? widthFormatted : widthFormatted - 1.5;
                                if (subField.elementType === 'INPUT_TEXT')
                                    return (
                                        <Collumn
                                            key={index}
                                            style={{
                                                width: `${widthh}%`,
                                            }}
                                        >
                                            <label
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 14,
                                                    marginBottom: 9,
                                                }}
                                            >
                                                {subField.title ?? ''}
                                                {subField.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                                            </label>
                                            {subField.subTitle && <span
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 12,
                                                    marginBottom: 9,
                                                }}
                                            >
                                                {subField.subTitle}
                                            </span>}

                                            <input
                                                {...register(subField.name)}
                                                className={styles.input}
                                                placeholder="Type here..."
                                            />

                                            <ErrorMessage
                                                errors={errors}
                                                name={subField.name}
                                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                                            />
                                        </Collumn>
                                    )

                                if (subField.elementType === 'INPUT_NUMBER')
                                    return (
                                        <Collumn
                                            key={index}
                                            style={{
                                                width: `${widthh}%`,
                                            }}
                                        >
                                            <label
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 14,
                                                    marginBottom: 9,
                                                }}
                                            >
                                                {subField.title ?? ''}
                                                {subField.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                                            </label>
                                            {subField.subTitle && <span
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 12,
                                                    marginBottom: 9,
                                                }}
                                            >
                                                {subField.subTitle}
                                            </span>}

                                            <input
                                                {...register(subField.name)}
                                                type="number"
                                                className={styles.input}
                                                placeholder="Type here..."
                                            />

                                            <ErrorMessage
                                                errors={errors}
                                                name={subField.name}
                                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                                            />
                                        </Collumn>
                                    )

                                if (subField.elementType === 'RADIO_BUTTON_GROUP') {
                                    const buttonSelectedd = watch(subField.name);

                                    return (
                                        <Collumn
                                            key={index}
                                            style={{ flex: 1 }}
                                        >
                                            {subField.title && <label
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 14,
                                                    marginBottom: 9
                                                }}
                                            >
                                                {subField.title ?? ''}
                                                {subField.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                                            </label>}
                                            {subField.subTitle && <span
                                                style={{
                                                    color: "#161616",
                                                    fontFamily: "InterSemiBold",
                                                    fontSize: 12,
                                                    marginBottom: 9,
                                                }}
                                            >
                                                {subField.subTitle}
                                            </span>}

                                            <Row
                                                key={index}
                                                style={{
                                                    width: '100%',
                                                    minWidth: 0,
                                                    gap: 22,
                                                }}
                                            >
                                                {
                                                    subField?.options?.map((option: any) => {

                                                        if (subField?.layoutType === 'BUTTON')
                                                            return (
                                                                <CenteredDiv
                                                                    key={option.order}
                                                                    className={styles.button}
                                                                    onClick={() => {
                                                                        setValue(subField.name, option.order)
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: buttonSelectedd === option.order ? colors.primaryOrange : "white",
                                                                        color: buttonSelectedd === option.order ? "white" : colors.primaryOrange,
                                                                        width: `${100 / subField.options.length}%`
                                                                    }}>
                                                                    {option.name}
                                                                </CenteredDiv>
                                                            )

                                                        if (subField?.layoutType === 'DEFAULT')
                                                            return (
                                                                <label key={option.order} className={styles.radio}>
                                                                    <input
                                                                        type="radio"
                                                                        {...register(field.name)}
                                                                        value={option.value}
                                                                    />
                                                                    <CenteredDiv className={styles.checkmark}>
                                                                        <MdCheck />
                                                                    </CenteredDiv>
                                                                    <CenteredDiv style={{ height: 28 }}>
                                                                        {option.name}
                                                                    </CenteredDiv>
                                                                </label>
                                                            )
                                                    }
                                                    )
                                                }
                                            </Row>
                                            <ErrorMessage
                                                errors={errors}
                                                name={field.name}
                                                render={({ message }) => message && <span className={styles.error}>{message}</span>}
                                            />
                                        </Collumn>
                                    )
                                }
                                return null;
                            }
                            )}
                        </Collumn>
                    )

                return null
            })}
        </Row>
    )
}

export default FullFormComponents;