import { colors } from "../../utils/colors";
import { Row } from "../Layouts";

interface FullFormBottomButtonsProps {
  step: number;
  setStep: any;
  onNext?: () => void
  onGoBack?: () => void
}

const FullFormBottomButtons: React.FC<FullFormBottomButtonsProps> = ({ step, setStep, onNext, onGoBack }) => {
  return (
    <Row
      style={{
        gap: 20,
        marginTop: 30,
        justifyContent: "center",
      }}
    >

      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          if (onGoBack) {
            onGoBack()
          } else {
            setStep((prev: number) => prev - 1);
          }
        }}
        style={{
          width: 200,
          height: 44,
          borderRadius: 8,
          background: "#EBEBEB",
          border: "none",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            color: "#161616",
            fontFamily: "InterBold",
            fontSize: 14,
          }}
        >
          Back
        </span>
      </button>

      {step < 5 || (step === 5 && onNext)
        ?
        <button
          type={'button'}
          onClick={() => {
            if (onNext) {
              onNext()
            } else {
              setStep((prev: number) => prev + 1)
            }
          }}
          style={{
            width: 200,
            height: 44,
            borderRadius: 8,
            background: colors.primaryOrange,
            border: "none",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              color: "white",
              fontFamily: "InterBold",
              fontSize: 14,
            }}
          >
            Next
          </span>
        </button>
        :
        <button
          type={'submit'}
          style={{
            width: 200,
            height: 44,
            borderRadius: 8,
            background: colors.primaryOrange,
            border: "none",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              color: "white",
              fontFamily: "InterBold",
              fontSize: 14,
            }}
          >
            Next
          </span>
        </button>}
    </Row>
  )
}

export default FullFormBottomButtons;