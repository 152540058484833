import { CSSProperties } from "react";
import { CenteredDiv } from "../Layouts";
import styles from "./index.module.css";

interface IButton {
  title: string;
  onClick: () => void;
  style?: CSSProperties;
}

export const ApproveButton: React.FC<IButton> = ({ title, onClick, style }) => {
  return (
    <CenteredDiv
      className={styles.approveButton}
      style={style}
      onClick={onClick}
    >
      {title}
    </CenteredDiv>
  );
};

export const RejectButton: React.FC<IButton> = ({ title, onClick, style }) => {
  return (
    <CenteredDiv
      className={styles.rejectButton}
      style={style}
      onClick={onClick}
    >
      {title}
    </CenteredDiv>
  );
};
