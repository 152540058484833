"use client";
import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.css";
import foto from "../../assets/fillFullForm.png";
import { IoArrowForward } from "react-icons/io5";

const FillFullForm = () => {
  return (
    <div>
      <Header modeDark isAuth />

      <Row className={styles.content} >
        <Collumn
          style={{
            justifyContent: "center",
            gap: 22,
            paddingRight: 30,
            minWidth: '40vw'
          }}
        >
          <span className={styles.title}>
            You're about to become an Inter agent!
          </span>
          <span className={styles.subTitleOrange}>
            Our pre-registration form takes an average of 40 minutes to complete.
          </span>

          <Link href="/full-form">
            <CenteredDiv className={styles.applyButton}>
              <span className={styles.applyButtonText}>Fill out the form</span>
              <IoArrowForward
                size={24}
                color="white"
                style={{ marginLeft: 12 }}
              />
            </CenteredDiv>
          </Link>
        </Collumn>
        <img src={foto} />
      </Row>

      <Footer />
    </div>
  );
};

export default FillFullForm;
