import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from '../locales/en/translation.json';
import translationsInPort from '../locales/pt/translation.json';

const resources = {
    en: {
        translation: translationsInEng
    },
    pt: {
        translation: translationsInPort
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("lang") ?? "pt",
        debug: true,
        fallbackLng: "pt",
        interpolation: {
            escapeValue: false
        },
        ns: "translation",
        defaultNS: "translation"
    });

export default i18n;