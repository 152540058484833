import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import BecomeAnAgent from "./pages/BecomeAnAgent";
import ShortForm from "./pages/ShortForm";
import Login from "./pages/Login";
import FindAgent from "./pages/FindAgents";
import ForgotPassword from "./pages/ForgotPassword";
import RecoverPassword from "./pages/RecoverPassword";
import FillFullForm from "./pages/FillFullForm";
import FullFormScreen from "./pages/FullForm";
import Register from "./pages/Register";
import FullFormCompleted from "./pages/FullFormCompleted";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/become-an-agent",
    element: <BecomeAnAgent />,
  },
  {
    path: "/short-form",
    element: <ShortForm />,
  },
  {
    path: "/find-agent",
    element: <FindAgent />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/recover-password",
    element: <RecoverPassword />,
  },
  {
    path: "/fill-full-form",
    element: <FillFullForm />,
  },
  {
    path: "/full-form",
    element: <FullFormScreen />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/full-form-completed",
    element: <FullFormCompleted />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
