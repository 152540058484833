"use client";
import Header from "../../components/Header";
import { Collumn } from "../../components/Layouts";
import ShortForm from "../../components/ShortForm";
import styles from './index.module.css'

const ShortFormScreen = () => {

  return (
    <>
      <Header modeDark />
      <Collumn className={styles.container}>
        <ShortForm />
      </Collumn>
    </>
  );
};

export default ShortFormScreen;
