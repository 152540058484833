import React from "react";
import testmonialBorder from "../../assets/testimonialBorder.svg";
import testmonialBorderSm from "../../assets/testimonialBorderExSm.svg";
import quotes from "../../assets/quotes.svg";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import { CenteredDiv, Collumn } from "../Layouts";
import useWindowDimensions from "../../utils/layout";

interface TestimonialsProps {
  text: string;
  name: string;
  position: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({
  text,
  name,
  position,
}) => {

  const { width } = useWindowDimensions()

  return (
    <CenteredDiv style={{ width: '100%'}}>
      <Collumn
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginBottom: 100,
        }}
      >
        <Collumn
          style={{
            marginBottom: 15,
            position: "relative",
          }}
        >
          <img
            src={quotes}
            alt="quotes"
            style={{
              position: "absolute",
              top: width < 800 ? 22 : 18,
              left: width < 800 ? 30 : 57,
              zIndex: 2,
              width: width < 800 ? 51 : 100,
              height: width < 800 ? 43 : 84,
            }}
          />
          <span
            style={{
              position: "absolute",
              top: width < 800 ? 81 : 129,
              left: width < 800 ? 30 : 57,
              zIndex: 2,
              fontFamily: "InterBold",
              fontSize: 16,
              lineHeight: "150%",
              width: "85%",
              textAlign: "left",
              color: colors.neutralGray04,
            }}
          >
            {text}
          </span>

          {width > 800 ?
            <img
              className={styles.buttonn}
              src={testmonialBorder}
              alt="testimonial"
            />
            :
            <img
              className={styles.buttonn}
              src={testmonialBorderSm}
              alt="testimonial"
            />
          }
        </Collumn>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              fontFamily: "InterBold",
              fontSize: 18,
              color: colors.primaryOrange,
              marginBottom: 4,
            }}
          >
            {name}
          </span>
          <span
            style={{
              fontFamily: "InterSemiBold",
              fontSize: 18,
              color: colors.neutralGray02,
            }}
          >
            {position}
          </span>
        </div>
      </Collumn>
    </CenteredDiv>
  )
};

export default Testimonials;
