"use client";
import React, { useState } from "react";
import { Collumn } from "../Layouts";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./index.module.css";

import FullFormContainer from "../FullFormContainer";
import Screen1 from "./screens/Screen1";
import Screen2 from "./screens/Screen2";
import Screen3 from "./screens/Screen3";
import Screen4 from "./screens/Screen4";
import Screen5 from "./screens/Screen5";
import Screen6 from "./screens/Screen6";
import Screen7 from "./screens/Screen7";
import Screen8 from "./screens/Screen8";

const FullFormStep4: React.FC<any> = ({ step, setStep }) => {
  const [screen, setScreen] = useState(1)

  const renderScreen: any = {
    1: <Screen1 />,
    2: <Screen2 />,
    3: <Screen3 />,
    4: <Screen4 />,
    5: <Screen5 />,
    6: <Screen6 />,
    7: <Screen7 />,
    8: <Screen8 />,
  }

  return (
    <FullFormContainer
      step={step}
      setStep={setStep}
      onNext={() => {
        if (screen < 8)
          setScreen(prev => prev + 1)
        else {
          setStep(step + 1)
        }
      }}
    >
      <Collumn style={{ flex: 1, gap: 22, width: 743, height: '60vh' }}>
        <span className={styles.stepTitle}>
          Certifications
        </span>
        {renderScreen[screen]}
      </Collumn >
    </FullFormContainer>
  )
};

export default FullFormStep4;
