"use client";
import { useRef, useState } from "react";
import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logoWhite.svg";
import logoSmall from "../../assets/logoSmall.svg";
import brazilFlag from "../../assets/brazilFlag.svg";
import usaFlag from "../../assets/usaFlag.svg";
import { colors } from "../../utils/colors";
import styles from "./index.module.css";
import { MdLogin } from "react-icons/md";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Collumn, Link, Row } from "../Layouts";
import useWindowDimensions from "../../utils/layout";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const Header = ({ modeDark, hasBackground, isAuth }: { modeDark?: boolean, hasBackground?: boolean, isAuth?: boolean }) => {
  const [openAgentsMenu, setOpenAgentsMenu] = useState(false);
  const [openLoginMenu, setOpenLoginMenu] = useState(false);
  const menu = useRef<any>(null);
  const { width } = useWindowDimensions();
  const { t } = useTranslation()

  const handleFlagChange = (event: any, newFlag: string) => {
    if (newFlag !== null) {
      i18n.changeLanguage(newFlag);
      localStorage.setItem('lang', newFlag)
    }
  };


  const MenuItem = ({
    title,
    className,
    id,
    href,
    onMouseEnter,
    icon
  }: {
    title: string;
    href?: string;
    className?: string;
    id?: string;
    onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
    icon?: React.ReactNode
  }) => (
    <a
      href={href}
      style={{
        textDecoration: "none",
        color: "inherit",
        fontFamily: "Inter",
      }}
      className={className}
      id={id}
      onMouseEnter={onMouseEnter}
    >
      <span
        className={styles.modalItemText}
        style={{
          color: modeDark ? "#161616" : width <= 1000 ? "#161616" : "white",
          display: "flex",
          flexWrap: "nowrap",
          fontFamily: modeDark
            ? "InterSemiBold"
            : width <= 1000
              ? "InterSemiBold"
              : "Inter",
        }}
      >
        {title}
      </span>
    </a>
  );

  const ItemsMenu = (
    <div className={styles.containerMenuOptions}>
      <MenuItem
        title={t("Header.Home")}
        href="/"
        onMouseEnter={() => {
          setOpenAgentsMenu(false)
          setOpenLoginMenu(false)
        }}
      />
      <MenuItem
        title={t("Header.Agents")}
        id="agentButton"
        className={styles.agentButton}
        onMouseEnter={() => {
          setOpenAgentsMenu(true)
          setOpenLoginMenu(false)
        }}
      />

      <div
        className={styles.loginButton}
        onMouseEnter={() => {
          if (!isAuth) setOpenLoginMenu(true)
          setOpenAgentsMenu(false)
        }}
      >
        <span
          className={styles.modalItemText}
          style={{
            color: colors.primaryOrange,
            fontFamily: "Inter",
            fontWeight: 'bold'
          }}
        >
          {isAuth ? 'Linda Watson' : t("Header.Account")}
        </span>
        <MdLogin
          color={colors.primaryOrange}
          style={{ marginLeft: 8 }}
          size={24}
        />
      </div>
    </div>

  )

  return (
    <div className={styles.containerBox}>
      <Collumn
        className={styles.container}
        style={{
          background: width <= 1000
            ? 'white'
            : openAgentsMenu || openLoginMenu || hasBackground
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(0,0,0,0)",
          backdropFilter: openAgentsMenu || openLoginMenu || hasBackground ? "blur(50px)" : "none",
        }}
      >
        {width <= 1000 ? (
          <Collumn
            style={{
              flex: 1,
              paddingTop: 24,
              minWidth: 50,
            }}
          >
            <Row
              style={{
                justifyContent: "space-between",
              }}
            >
              <Link href={"/"}>
                <img src={logoSmall} alt="logo" />
              </Link>
              <ToggleButtonGroup
                value={i18n.language}
                exclusive
                onChange={handleFlagChange}
                aria-label="flag switch"
                className={styles.switchLanguage}
                style={{ background: "white", paddingBottom: 25 }}
              >
                <ToggleButton
                  value="pt"
                  aria-label="Brazil Flag"
                  className={styles.switchItem1}
                >
                  <img
                    src={brazilFlag}
                    alt="Brazil Flag"
                    width={30}
                    height={20}
                  />
                </ToggleButton>
                <ToggleButton
                  value="en"
                  aria-label="USA Flag"
                  className={styles.switchItem1}
                >
                  <img src={usaFlag} alt="USA Flag" width={30} height={20} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Row>
            {ItemsMenu}
          </Collumn>
        ) : (
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Link href={"/"}>
              <img src={logo} alt="logo" width={200} height={30} style={{ width: '100%', height: '100%', contentVisibility: 'visible', transform: 'translate3d(0px, 0px, 0px)' }} />
            </Link>

            {ItemsMenu}
          </Row>
        )}
        {openAgentsMenu && (
          <div
            id={"agentMenu"}
            ref={menu}
            className={styles.menuContainer}
            onMouseLeave={() => {
              setOpenAgentsMenu(false)
              setOpenLoginMenu(false)
            }}
          >
            <div className={styles.menu}>
              <span
                className={styles.menuTitle}
                style={{
                  color: width > 1000 ? 'white' : colors.neutralGray05
                }}
              >
                Agentes
              </span>
              <a style={{ textDecoration: "none", color: 'inherit' }} href="become-an-agent">
                <span
                  className={styles.menuItem}
                  style={{
                    color: width > 1000 ? 'white' : colors.neutralGray05
                  }}
                >
                  Se torne um agente
                </span>
              </a>

              <a style={{ textDecoration: "none" }} href="/find-agent">
                <span
                  className={styles.menuItem}
                  style={{
                    color: width > 1000 ? 'white' : colors.neutralGray05
                  }}
                >
                  Onde encontrar agentes Inter?
                </span>
              </a>
            </div>
          </div>
        )}

        {openLoginMenu && (
          <div
            id={"loginMenu"}
            ref={menu}
            className={styles.menuContainer}
            onMouseLeave={() => {
              setOpenAgentsMenu(false)
              setOpenLoginMenu(false)
            }}
          >
            <div className={styles.menu}>
              <span
                className={styles.menuTitle}
                style={{
                  color: width > 1000 ? 'white' : colors.neutralGray05
                }}
              >
                Login
              </span>
              <a style={{ textDecoration: "none", color: 'inherit' }} href="https://agent.inter.co/login?redirect=%2F">
                <span
                  className={styles.menuItem}
                  style={{
                    color: width > 1000 ? 'white' : colors.neutralGray05
                  }}
                >
                  Entrar como Agente
                </span>
              </a>

              <a style={{ textDecoration: "none" }} href="/login">
                <span
                  className={styles.menuItem}
                  style={{
                    color: width > 1000 ? 'white' : colors.neutralGray05
                  }}
                >
                  Continuar Aplicação
                </span>
              </a>
            </div>
          </div>
        )}
      </Collumn>
    </div >
  );
};

export default Header;
