// src/components/Alert.js
import React from "react";
import styles from "./index.module.css";
import { GrClose } from "react-icons/gr";
import { CenteredDiv } from "../Layouts";
import { ApproveButton, RejectButton } from "../Buttons";

interface IAlert {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  approveText?: string;
  rejectText?: string;
  onApprove?: () => void;
}

const Alert: React.FC<IAlert> = ({
  open,
  title,
  message,
  onClose,
  approveText,
  rejectText,
  onApprove,
}) => {
  return open ? (
    <div className={styles["alert-background"]}>
      <div className={styles["alert-container"]}>
        <GrClose
          onClick={onClose}
          color="#3E322E"
          size={20}
          className={styles["alert-close-button"]}
        />
        <CenteredDiv>
          <span className={styles.title}>{title}</span>
        </CenteredDiv>
        <div className={styles["alert-message"]}>{message}</div>
        {approveText && (
          <ApproveButton title={approveText} onClick={onApprove ?? onClose} />
        )}
        {rejectText && (
          <RejectButton title={rejectText} onClick={onClose ?? onClose} />
        )}
      </div>
    </div>
  ) : null;
};

export default Alert;
