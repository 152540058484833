"use client";
import { useEffect, useRef, useState } from "react";
import { Collumn } from "../../components/Layouts";
import ProgressSteps from "../../components/ProgressSteps";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FullFormStep1 from "../../components/FullFormStep1";
import FullFormStep2 from "../../components/FullFormStep2";
import FullFormStep3 from "../../components/FullFormStep3";
import FullFormStep4 from "../../components/FullFormStep4";
import FullFormStep5 from "../../components/FullFormStep5";
import FullFormStep6 from "../../components/FullFormStep6";
import styles from './index.module.css'
import { useNavigate } from "react-router-dom";
import HeaderForms from "../../components/HeaderForms";

export interface Competitor {
  name: string;
  country: 'brazil' | 'others' | '';
  countryName?: string;
  creditLimit: string;
}
export interface FormDataFullForm {
  commercialType: string;
  commercialDocument: File;
  businessLegalName: string;
  businessDBA: string;
  businessDBADocument: File;
  businessZip: string;
  businessAddress: string;
  businessState: string;
  businessCity: string;
  businessCountry: string;
  businessAgencyPhone: string;
  businessEmail: string;
  businessOccupation: string;
  businessWebsite: string;
  businessOwnerCellPhone: string;
  businessOwnerHomePhone: string;
  dateIssued: string;
  businessLicense: string;
  taxId: string;
  expirationDate: string;
  issuedBy: string;
  productsOrServicesOffered: string;
  language: string;
  otherLanguage: string;
  competitors: Competitor[];
  dateAcquired: string;
  dateBusinessStarted: string;
  localSituation: string;
  stablishedSince: string;
  moneyTransmitterRelationship: string;
  moneyTransmitterRelationshipSince: string;
  moneyTransmitterTerminated: string;
  moneyTransmitterTerminatedInfo: string;
  ownerFullLegalName: string;
  ownerTitle: string;
  ownerPercentOwnership: string;
  ownerGovernmentIdNumber: string;
  ownerSsn: string;
  ownerZipCode: string;
  ownerAddress: string;
  ownerState: string;
  ownerCountry: string;
  ownerCity: string;
  ownerCellphone: string;
  ownerCitizenshipProof: File;
  antiMoneyLdrName: string;
  antiMoneyLdrBusinessPhone: string;
  antiMoneyLdrEmail: string;
  antiMoneyLdrCellPhone: string;
  antiMoneyLdrCashChecksMoreThan1000PerDay: string;
  antiMoneyLdrExchangeMoreThan1000PerDay: string;
  antiMoneyLdrExplanation: string;
  antiMoneyLdrFinCENRegistration: string;
  criminalHistoryEverBeenArrested: string;
  criminalHistoryExplanation: string;
  criminalHistoryFile: string;
  certificationsBusinessFelony: string;
  certificationsBusinessFelonyExplanation: string;
  certificationsBusinessFelonyFile: File;
  certificationsOtherOwnerFelony: string;
  certificationsOtherOwnerFelonyExplanation: string;
  certificationsOtherOwnerFelonyFile: File;
  certificationsBankrupt: string;
  certificationsBankruptExplanation: string;
  certificationsBankruptFile: File;
  certificationsRevokedBusinessActivity: string;
  certificationsRevokedBusinessActivityExplanation: string;
  certificationsRevokedBusinessActivityFile: File;
  certificationsReorganizationApplication: string;
  certificationsReorganizationApplicationExplanation: string;
  certificationsReorganizationApplicationFile: File;
  certificationsThirdPartyApplication: string;
  certificationsThirdPartyApplicationExplanation: string;
  certificationsThirdPartyApplicationFile: File;
  certificationsJudgmentSuffered: string;
  certificationsJudgmentSufferedExplanation: string;
  certificationsJudgmentSufferedFile: File;
  certificationsPleadNoloContendere: string;
  certificationsPleadNoloContendereExplanation: string;
  certificationsPleadNoloContendereFile: File;
  achDepositaryName: string;
  achBranch: string;
  achZipCode: string;
  achAddress: string;
  achState: string;
  achCity: string;
  achCountry: string;
  achTransit: string;
  achAccountType: string;
  achBusinessName: string;
  achExactName: string;
  achBusinessLicense: File;
  achVoidedCheck: File;
  documentsUtilityBill: File;
  documentsVoidCheck: File;
  documentsFormSS4: File;
  documentsAgentLocationInteriorPhotos: File[];
  documentsAgentLocationExteriorPhotos: File[];
}

const FullFormScreen = () => {
  const [step, setStep] = useState(0);
  const [skip, setSkip] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetch('https://interagents.api.oke.luby.me/forms/full-form', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then((a) => a.json())
      .then((result) => {
        localStorage.setItem("formConfig", JSON.stringify(result.steps))
      })
      .catch((e) => console.log(e))
  }, [])

  const schemaStep: any = yup.object().shape({
    commercialType: yup.string().required('invalid field'),
    // commercialDocument: yup.string().test("fileExist", "invalid field", (value: any) => {
    //   return !value
    // }),
    businessLegalName: yup.string().required("invalid field"),
    businessDBA: yup.string().required("invalid field"),
    businessDBADocument: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    businessZip: yup.string().required("invalid field"),
    businessAddress: yup.string().required("invalid field"),
    businessState: yup.string().required("invalid field"),
    businessCity: yup.string().required("invalid field"),
    businessCountry: yup.string().required("invalid field"),
    businessAgencyPhone: yup.string().required("invalid field"),
    businessEmail: yup.string().email("formato inválido").required("invalid field"),
    businessWebsite: yup.string().required("invalid field"),
    businessOwnerCellPhone: yup.string().required("invalid field"),
    businessOwnerHomePhone: yup.string().required("invalid field"),
    dateIssued: yup.string().required("invalid field"),
    businessLicense: yup.string().required("invalid field"),
    taxId: yup.string().required("invalid field"),
    expirationDate: yup.string().required("invalid field"),
    issuedBy: yup.string().required("invalid field"),
    productsOrServicesOffered: yup.string().required("invalid field"),
    language: yup.string().required("invalid field"),
    otherLanguage: yup.string().required('invalid field'),
    competitors: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        creditLimit: yup.string(),
        country: yup.string(),
        countryName: yup.string()
      })
    ),
    dateAcquired: yup.string(),
    dateBusinessStarted: yup.string().required("invalid field"),
    localSituation: yup.string().required("invalid field"),
    stablishedSince: yup.string().required("invalid field"),
    moneyTransmitterRelationship: yup.string().required("invalid field"),
    moneyTransmitterRelationshipSince: yup.string(),
    moneyTransmitterTerminated: yup.string().required("invalid field"),
    moneyTransmitterTerminatedInfo: yup.string(),
    ownerFullLegalName: yup.string().required("invalid field"),
    ownerTitle: yup.string().required("invalid field"),
    ownerPercentOwnership: yup.string().required("invalid field"),
    ownerGovernmentIdNumber: yup.string().required("invalid field"),
    ownerSsn: yup.string().required("invalid field"),
    ownerZipCode: yup.string().required("invalid field"),
    ownerAddress: yup.string().required("invalid field"),
    ownerState: yup.string().required("invalid field"),
    ownerCountry: yup.string().required("invalid field"),
    ownerCity: yup.string().required("invalid field"),
    ownerCellphone: yup.string().required("invalid field"),
    ownerCitizenshipProof: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    antiMoneyLdrName: yup.string().required("invalid field"),
    antiMoneyLdrBusinessPhone: yup.string().required("invalid field"),
    antiMoneyLdrEmail: yup.string().required("invalid field"),
    antiMoneyLdrCellPhone: yup.string().required("invalid field"),
    antiMoneyLdrCashChecksMoreThan1000PerDay: yup.string().required("invalid field"),
    antiMoneyLdrExchangeMoreThan1000PerDay: yup.string().required("invalid field"),
    antiMoneyLdrExplanation: yup.string(),
    antiMoneyLdrFinCENRegistration: yup.mixed(),
    criminalHistoryEverBeenArrested: yup.string().required("invalid field"),
    criminalHistoryExplanation: yup.string().required("invalid field"),
    criminalHistoryFile: yup.string().required("invalid field"),
    certificationsBusinessFelony: yup.string().required("invalid field"),
    certificationsBusinessFelonyExplanation: yup.string().required("invalid field"),
    certificationsBusinessFelonyFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsOtherOwnerFelony: yup.string().required("invalid field"),
    certificationsOtherOwnerFelonyExplanation: yup.string().required("invalid field"),
    certificationsOtherOwnerFelonyFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsBankrupt: yup.string().required("invalid field"),
    certificationsBankruptExplanation: yup.string().required("invalid field"),
    certificationsBankruptFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsRevokedBusinessActivity: yup.string().required("invalid field"),
    certificationsRevokedBusinessActivityExplanation: yup.string().required("invalid field"),
    certificationsRevokedBusinessActivityFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsReorganizationApplication: yup.string().required("invalid field"),
    certificationsReorganizationApplicationExplanation: yup.string().required("invalid field"),
    certificationsReorganizationApplicationFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsThirdPartyApplication: yup.string().required("invalid field"),
    certificationsThirdPartyApplicationExplanation: yup.string().required("invalid field"),
    certificationsThirdPartyApplicationFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsJudgmentSuffered: yup.string().required("invalid field"),
    certificationsJudgmentSufferedExplanation: yup.string().required("invalid field"),
    certificationsJudgmentSufferedFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    certificationsPleadNoloContendere: yup.string().required("invalid field"),
    certificationsPleadNoloContendereExplanation: yup.string().required("invalid field"),
    certificationsPleadNoloContendereFile: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    achDepositaryName: yup.string().required("invalid field"),
    achBranch: yup.string().required("invalid field"),
    achZipCode: yup.string().required("invalid field"),
    achAddress: yup.string().required("invalid field"),
    achState: yup.string().required("invalid field"),
    achCity: yup.string().required("invalid field"),
    achCountry: yup.string().required("invalid field"),
    achTransit: yup.string().required("invalid field"),
    achAccountType: yup.string().required("invalid field"),
    achBusinessName: yup.string().required("invalid field"),
    achExactName: yup.string().required("invalid field"),
    achBusinessLicense: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    achVoidedCheck: yup.mixed(),
    documentsUtilityBill: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    documentsVoidCheck: yup.mixed(),
    documentsFormSS4: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    documentsAgentLocationInteriorPhotos: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
    documentsAgentLocationExteriorPhotos: yup.mixed().test("fileExist", "invalid field", (value: any) => {
      return !value
    }),
  });

  const renderFormStep: any = {
    1: <FullFormStep1 step={step} setStep={setStep} />,
    2: <FullFormStep2 step={step} setStep={setStep} />,
    3: <FullFormStep3 step={step} setStep={setStep} />,
    4: <FullFormStep4 step={step} setStep={setStep} />,
    5: <FullFormStep5 step={step} setStep={setStep} />,
    6: <FullFormStep6 step={step} setStep={setStep} skip={skip} setSkip={setSkip} />,
  }

  const methods = useForm<FormDataFullForm>({
    resolver: yupResolver(schemaStep),
    mode: 'onChange',
    defaultValues: {
      competitors: [
        {
          name: "",
          country: "",
          creditLimit: "",
          countryName: ''
        }
      ]
    }
  });


  const { handleSubmit } = methods;


  const onSubmit: SubmitHandler<FormDataFullForm> = (data: any) => {
    navigate('/full-form-completed')
    console.log(JSON.stringify(data));
  };

  const cRef = useRef<any>()
  useEffect(() => {
    cRef.current.scrollTo(0,0);
  }, [step]);


  return (
    <>
      <HeaderForms />
      <Collumn
        ref={cRef}
        className={styles.container}
      >
        <div style={{ padding: '32px 50px', borderRadius: 8, background: 'white', boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)' }}>

          <ProgressSteps step={step} />

          <FormProvider {...methods}>
            <div style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex' }}>
              <form onSubmit={handleSubmit(onSubmit, () => { setSkip(true) })} style={{ paddingTop: 50 }}>
                {renderFormStep[step + 1]}
              </form>
            </div>
          </FormProvider>
        </div>
      </Collumn>
    </>
  );
};

export default FullFormScreen;
