"use client";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Collumn, Row } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import ShortFormStep1 from "../ShortFormStep1";
import Alert from "../Alert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export interface FormData {
  name: string;
  email: string;
  phone: string;
  zip: string;
  address: string;
  state: string;
  city: string;
  country: string;
  companyName: string;
  storeAddress: string;
  message: string;
}

const ShortForm: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [leaveAlertIsOpen, setLeaveAlertIsOpen] = useState(false);

  const schemaStep1: any = yup.object().shape({
    name: yup.string().required("invalid field"),
    email: yup.string().email("formato inválido").required("invalid field"),
    phone: yup.string().required("invalid field"),
    zip: yup.string().required("invalid field"),
    address: yup.string().required("invalid field"),
    state: yup.string().required("invalid field"),
    city: yup.string().required("invalid field"),
    country: yup.string().required("invalid field"),
    companyName: yup.string().required("invalid field"),
    storeAddress: yup.string().required("invalid field"),
    message: yup.string(),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schemaStep1),
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<FormData> = (data: any) => {
    fetch('https://interagents.api.oke.luby.me/leads', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    setOpen(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form style={{ width: 743, height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Collumn style={{ alignItems: 'center' }}>
            <span className={styles.stepTitle}>
              Pré-Cadastro
            </span>
            <span className={styles.stepSubtitle}>
              Preencha seu dados para que nosso time entre em contato.
            </span>
          </Collumn>

          <ShortFormStep1 />

          <Row
            style={{
              gap: 20,
              marginTop: 30,
              width: 743,
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setLeaveAlertIsOpen(true);
              }}
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: "#EBEBEB",
                border: "none",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#161616",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                Voltar
              </span>
            </button>

            <button
              type="submit"
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: colors.primaryOrange,
                border: "none",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                Enviar
              </span>
            </button>
          </Row>
        </form>
      </FormProvider>

      <Alert
        message="Entraremos em contato assim que possível. Obrigado por aplicar!"
        title="Formulário enviado com sucesso!"
        onClose={() => {
          setOpen(false);
          navigate("/")
        }}
        approveText="INÍCIO"
        open={open}
      />

      <Alert
        title="Sair do Formulário"
        message="Você tem certeza que deseja sair do formulário agora? Todas as suas informações serão perdidas."
        onApprove={() => navigate("/become-an-agent")}
        open={leaveAlertIsOpen}
        onClose={() => setLeaveAlertIsOpen(false)}
        approveText="Sair"
        rejectText="Voltar ao Formulário"
      />
    </>
  );
};

export default ShortForm;
