import { useState } from "react"
import { colors } from "../../../../utils/colors"
import { Collumn, Row } from "../../../Layouts"
import styles from './index.module.css'
import { useFormContext } from "react-hook-form"
import { FormDataFullForm } from "../../../../pages/FullForm"
import FileInput from "../../../FileInput"

const Screen6 = () => {
    const [thirdParty, setThirdParty] = useState<string>();

    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext<FormDataFullForm>();

    return (
        <Collumn style={{ gap: 22 }}>
            <span className={styles.subtitle}>
            An application has been made by a third party for the appointment of a receiver, fiscal agent, or similar officer or for other relief of the kind described in paragraph above;
            </span>

            <Row
                style={{
                    gap: 20,
                    justifyContent: "center",
                }}
            >
                <button
                    type="button"
                    onClick={() => {
                        setThirdParty('yes')
                        setValue('certificationsThirdPartyApplication', 'yes')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: thirdParty === 'yes' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: thirdParty === 'yes' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        Yes
                    </span>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        setThirdParty('no')
                        setValue('certificationsThirdPartyApplication', 'no')
                    }}
                    style={{
                        width: 200,
                        height: 44,
                        borderRadius: 8,
                        background: thirdParty === 'no' ? colors.primaryOrange : "white",
                        border: "2px solid #EA7100",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{
                            color: thirdParty === 'no' ? 'white' : colors.primaryOrange,
                            fontFamily: "InterBold",
                            fontSize: 14,
                        }}
                    >
                        No
                    </span>
                </button>
            </Row>

            {
                thirdParty === 'yes' && (
                    <Collumn>
                        <span className={styles.subtitle}>
                            Please attached a detailed explanation<span style={{ color: colors.primaryOrange }}>*</span>
                        </span>
                        <textarea
                            {...register("certificationsThirdPartyApplicationExplanation")}
                            className={styles.input}
                            placeholder="Digite..."
                            rows={5}
                            style={{ marginTop: 8 }}
                        />
                        <span className={styles.error}>{errors?.certificationsThirdPartyApplicationExplanation?.message ?? ""}</span>

                        <span className={styles.subtitle} style={{ marginTop: 22 }}>
                            Attach a file, if you have one
                        </span>
                        <FileInput registerName={'certificationsThirdPartyApplicationFile'} />
                        <span className={styles.error}>{errors?.certificationsThirdPartyApplicationFile?.message ?? ""}</span>

                    </Collumn>
                )
            }
        </Collumn>
    )
}

export default Screen6;
