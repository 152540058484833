"use client";
import { Collumn, Row } from "../../components/Layouts";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.css";
import foto from "../../assets/fillFullForm.png";
import { MdTaskAlt } from "react-icons/md";
import { colors } from "../../utils/colors";

const FullFormCompleted = () => {
  return (
    <div>
      <Header modeDark isAuth />

      <Row className={styles.content} >
        <Collumn
          style={{
            justifyContent: "center",
            gap: 22,
            paddingRight: 30,
            width: '45vw'
          }}
        >
          <MdTaskAlt color={colors.primaryOrange} size={60} />
          <span className={styles.title}>
            Form submitted successfully!
          </span>
          <span className={styles.subTitleOrange}>
            We will contact you regarding your application shortly.
          </span>
        </Collumn>
        <div className={styles.image} style={{
          background: `url(${foto}) 0%/ cover no-repeat`,
          width: '60%',
          height: '100%'
        }}
        />


      </Row>

      <Footer />
    </div>
  );
};

export default FullFormCompleted;
