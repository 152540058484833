import React from "react";
import { CenteredDiv, Row } from "../Layouts";
import useWindowDimensions from "../../utils/layout";
interface BoxLogoHorizontalProps {
  Icon: any;
  title: string;
}

const BoxLogoHorizontal: React.FC<BoxLogoHorizontalProps> = ({
  Icon,
  title,
}) => {
  const { width: widthScreen } = useWindowDimensions()

  return (
    <Row
      style={{
        alignItems: "center",
        minWidth: widthScreen > 550 ? 410 : '75vw',
        minHeight: 136,
        borderRadius: 8,
        border: "1px solid #DEDFE4",
        padding: 22,
        gap: 20,
        background: 'white'
      }}
    >
      <CenteredDiv>{Icon}</CenteredDiv>
      <CenteredDiv style={{ flex: 1 }}>
        <span
          style={{
            fontFamily: "CitrinaBold",
            fontSize: widthScreen < 550 ? 19 : 24,
            color: "#161616",
            flexWrap: "wrap",
            textAlign: "center",
          }}
        >
          {title}
        </span>
      </CenteredDiv>
    </Row>
  )
};

export default BoxLogoHorizontal;
